export const globalExternalScripts = [
  // fontawesome
  {
    src: 'https://kit.fontawesome.com/f6e4df848a.js',
    crossorigin: 'anonymous',
  },
  // stripe
  {
    src: 'https://js.stripe.com/v3/',
    defer: 'defer',
  },
  // reCAPTCHA
  {
    src: 'https://www.google.com/recaptcha/api.js?render=6LeNqfAUAAAAAB7xA8LghLiZQDJ0Wddlw0OTwZQ1',
    async: true,
  },
  // iFrameResizer
  {
    src: 'https://reports.heymarv.com/app/iframeResizer.js',
    defer: 'defer',
  },
  // Facebook Pixel Code
  {
    innerHTML: `
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      );
      window.fbq.disablePushState = true;
    `,
  },
  // Google Tag Manager
  {
    innerHTML: `
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-TWZ87PK');
      function gtag() {
        dataLayer.push(arguments);
      }
    `,
  },
  // Calendly
  {
    src: 'https://assets.calendly.com/assets/external/widget.js',
    defer: 'defer',
  },
  // fontawesome
  {
    src: 'https://kit.fontawesome.com/f6e4df848a.js',
    crossorigin: 'anonymous',
  },
  // canva
  {
    src: 'https://sdk.canva.com/designbutton/v2/api.js',
    defer: 'defer',
  },
  // service worker
  {
    innerHTML: `
      try {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/sw.js');
        }
      } catch (e) {
        console.log('Service worker registration failed:', e);
      }
    `,
  }
];

export const globalExternalLinks = [
  {
    href: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap',
    rel: 'stylesheet',
  },
  {
    href: `/manifest.json`,
    rel: 'manifest',
  },
];

export function getGoogleAnalyticsScript(googleAnalyticsCode, studioUrl) {
  return `window.gtag('config', '${googleAnalyticsCode}', { groups: '${studioUrl}' });`
}

export function getRewardfulInitialScript() {
  return `
    (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');
  `;
}

export function initRewardful(rewardfulApiKey) {
  return {
    src: `//r.wdfl.co/rw.js`,
    async: true,
    type: 'text/javascript',
    'data-rewardful': rewardfulApiKey,
  };
}


export function getUserbackScript() {
  const userbackKey = process.env.VUE_APP_USERBACK_KEY;

  if (!userbackKey) {
    // userback key not set
    return;
  }

  return `
    window.Userback = window.Userback || {};
    if (window.Userback.access_token) {
      // already initialized
      return;
    }
    window.Userback.access_token = ${userbackKey};
    (function (id) {
      const s: any = document.createElement('script');
      s.async = 1;
      s.src = 'https://static.userback.io/widget/v1.js';
      const parent_node = document.head || document.body;
      parent_node.appendChild(s);
    })('userback-sdk');
  `;
}
