import { default as aboutvjEapSEjlhMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as eventsE6Mx9VWbdHMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93u8T8vvBMQbMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleA9ZfCYyzcmMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45products4Ydn35kJNfMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93Aez8ABz953Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as view6Dl63G3yiiMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexhrWkhLRrrlMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93mdJcNGqHpEMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexEJMNxv0c6rMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_939LKnFZwgVWMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexn6VLWvFG8yMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessions12bau9SLjZMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingshzzd7dPjGYMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsSU6lS2KWmoMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93VYcpMXqnLNMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as indexDktBE399NIMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirmWPvvt3tkCQMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirmXv2ujcASVRMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendarpagucfkRalMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/calendar.vue?macro=true";
import { default as chataMJbdSgXq5Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/chat.vue?macro=true";
import { default as aboutCUF6WkcDH2Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedWiPLbu6A9uMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersbiEEaZn0gDMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_939Z2e5m4pDuMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45editW3LZYgQk19Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_93Mhvr0RkUzpMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as communitybhaf4fpyrnMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_938u0aLTGZT4Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93m6p5OodB1JMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardwsp4STXZYpMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/dashboard.vue?macro=true";
import { default as index7UwLGcM1lVMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutvPgoMYTLDxMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45productg4bzYTuLSGMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventsKpUwHhBkxAMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93B7ZqGkYaxwMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexG05mlnrfZlMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_93Pa3io898jAMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_93ZsY7ZC0WwBMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as index2Qy6Q6hO5MMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_932jHNyZsmkZMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as join0LiZSC3lniMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/join.vue?macro=true";
import { default as loginkoSlsSoaucMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/login.vue?macro=true";
import { default as logoutZIlabLKLU5Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_93a8wk7Yp6oYMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_93t4XMBU8KULMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_93qOJxHyypZDMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favoritesDmRajmQX8GMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studioskebHFaCLpsMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optinjtxXTLgvu1Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_93tTe67zw6RLMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue?macro=true";
import { default as indexveSfqpxIBaMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indexwe5Z5QtZNBMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as aboutGe4REoB4O3Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as digital_45download_45contentyMGu8jnyAAMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/digital-download-content.vue?macro=true";
import { default as eventsvWuMw98CGCMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93l19xXdIjnvMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleqNskbRpVCvMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsRkVUyfrljnMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93vbItQYOd7vMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewDz3cymlnlXMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexYIIRke4oA3Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93kznGRJLXqQMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexfRiCPTdPvNMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93GbmSi7BgPJMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indextcfNmQgvskMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsGrAPTQV2zIMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordings2s9giX44q9Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsiQPxfOez8pMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93p2ocXnnfK6Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as indexAuTjsnwtTXMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexdtv2ZTXx6fMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchasesDLhLeIuHUZMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptions5oKCLNm1A0Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93hSglc3doxaMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_93mjkjM3Cd71Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as resetl1zD3qDjbTMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/reset.vue?macro=true";
import { default as shop88DDXHgXcTMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/shop.vue?macro=true";
import { default as signupu8PTvmCgbqMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/signup.vue?macro=true";
import { default as liveO95mSX99HiMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tips14zY4LNUb0Meta } from "/tmp/build_7e8da4c2/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_93EF37R6m2DGMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compactiIFjEBZGOAMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as full1EMZkSeOYFMeta } from "/tmp/build_7e8da4c2/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_93VYcpMXqnLNMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_93VYcpMXqnLNMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_93VYcpMXqnLNMeta || {},
    alias: _91product_id_93VYcpMXqnLNMeta?.alias || [],
    redirect: _91product_id_93VYcpMXqnLNMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutvjEapSEjlhMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: aboutvjEapSEjlhMeta?.path ?? "about",
    meta: aboutvjEapSEjlhMeta || {},
    alias: aboutvjEapSEjlhMeta?.alias || [],
    redirect: aboutvjEapSEjlhMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsE6Mx9VWbdHMeta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: eventsE6Mx9VWbdHMeta?.path ?? "events",
    meta: eventsE6Mx9VWbdHMeta || {},
    alias: eventsE6Mx9VWbdHMeta?.alias || [],
    redirect: eventsE6Mx9VWbdHMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93u8T8vvBMQbMeta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_93u8T8vvBMQbMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93u8T8vvBMQbMeta || {},
    alias: _91media_id_93u8T8vvBMQbMeta?.alias || [],
    redirect: _91media_id_93u8T8vvBMQbMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleA9ZfCYyzcmMeta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45sampleA9ZfCYyzcmMeta?.path ?? "free-sample",
    meta: free_45sampleA9ZfCYyzcmMeta || {},
    alias: free_45sampleA9ZfCYyzcmMeta?.alias || [],
    redirect: free_45sampleA9ZfCYyzcmMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45products4Ydn35kJNfMeta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45products4Ydn35kJNfMeta?.path ?? "included-products",
    meta: included_45products4Ydn35kJNfMeta || {},
    alias: included_45products4Ydn35kJNfMeta?.alias || [],
    redirect: included_45products4Ydn35kJNfMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93Aez8ABz953Meta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93Aez8ABz953Meta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93Aez8ABz953Meta || {},
    alias: _91media_id_93Aez8ABz953Meta?.alias || [],
    redirect: _91media_id_93Aez8ABz953Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: view6Dl63G3yiiMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: view6Dl63G3yiiMeta?.path ?? "lessons/:lesson_id()/view",
    meta: view6Dl63G3yiiMeta || {},
    alias: view6Dl63G3yiiMeta?.alias || [],
    redirect: view6Dl63G3yiiMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexhrWkhLRrrlMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indexhrWkhLRrrlMeta?.path ?? "lessons",
    meta: indexhrWkhLRrrlMeta || {},
    alias: indexhrWkhLRrrlMeta?.alias || [],
    redirect: indexhrWkhLRrrlMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93mdJcNGqHpEMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93mdJcNGqHpEMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93mdJcNGqHpEMeta || {},
    alias: _91media_id_93mdJcNGqHpEMeta?.alias || [],
    redirect: _91media_id_93mdJcNGqHpEMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexEJMNxv0c6rMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexEJMNxv0c6rMeta?.path ?? "playlists/:playlist_id()",
    meta: indexEJMNxv0c6rMeta || {},
    alias: indexEJMNxv0c6rMeta?.alias || [],
    redirect: indexEJMNxv0c6rMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_939LKnFZwgVWMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_939LKnFZwgVWMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_939LKnFZwgVWMeta || {},
    alias: _91media_id_939LKnFZwgVWMeta?.alias || [],
    redirect: _91media_id_939LKnFZwgVWMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexn6VLWvFG8yMeta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: indexn6VLWvFG8yMeta?.path ?? "playlists",
    meta: indexn6VLWvFG8yMeta || {},
    alias: indexn6VLWvFG8yMeta?.alias || [],
    redirect: indexn6VLWvFG8yMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessions12bau9SLjZMeta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessions12bau9SLjZMeta?.path ?? "private-sessions",
    meta: private_45sessions12bau9SLjZMeta || {},
    alias: private_45sessions12bau9SLjZMeta?.alias || [],
    redirect: private_45sessions12bau9SLjZMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingshzzd7dPjGYMeta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordingshzzd7dPjGYMeta?.path ?? "recordings",
    meta: recordingshzzd7dPjGYMeta || {},
    alias: recordingshzzd7dPjGYMeta?.alias || [],
    redirect: recordingshzzd7dPjGYMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsSU6lS2KWmoMeta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewsSU6lS2KWmoMeta?.path ?? "reviews",
    meta: reviewsSU6lS2KWmoMeta || {},
    alias: reviewsSU6lS2KWmoMeta?.alias || [],
    redirect: reviewsSU6lS2KWmoMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexDktBE399NIMeta?.name ?? "buy-product-product_id",
    path: indexDktBE399NIMeta?.path ?? "/buy/product/:product_id()",
    meta: indexDktBE399NIMeta || {},
    alias: indexDktBE399NIMeta?.alias || [],
    redirect: indexDktBE399NIMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmWPvvt3tkCQMeta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirmWPvvt3tkCQMeta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirmWPvvt3tkCQMeta || {},
    alias: confirmWPvvt3tkCQMeta?.alias || [],
    redirect: confirmWPvvt3tkCQMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmXv2ujcASVRMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirmXv2ujcASVRMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirmXv2ujcASVRMeta || {},
    alias: confirmXv2ujcASVRMeta?.alias || [],
    redirect: confirmXv2ujcASVRMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendarpagucfkRalMeta?.name ?? "calendar",
    path: calendarpagucfkRalMeta?.path ?? "/calendar",
    meta: calendarpagucfkRalMeta || {},
    alias: calendarpagucfkRalMeta?.alias || [],
    redirect: calendarpagucfkRalMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chataMJbdSgXq5Meta?.name ?? "chat",
    path: chataMJbdSgXq5Meta?.path ?? "/chat",
    meta: chataMJbdSgXq5Meta || {},
    alias: chataMJbdSgXq5Meta?.alias || [],
    redirect: chataMJbdSgXq5Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_939Z2e5m4pDuMeta?.name ?? "community-groups-group_id",
    path: _91group_id_939Z2e5m4pDuMeta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_939Z2e5m4pDuMeta || {},
    alias: _91group_id_939Z2e5m4pDuMeta?.alias || [],
    redirect: _91group_id_939Z2e5m4pDuMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutCUF6WkcDH2Meta?.name ?? "community-groups-group_id-about",
    path: aboutCUF6WkcDH2Meta?.path ?? "about",
    meta: aboutCUF6WkcDH2Meta || {},
    alias: aboutCUF6WkcDH2Meta?.alias || [],
    redirect: aboutCUF6WkcDH2Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedWiPLbu6A9uMeta?.name ?? "community-groups-group_id-feed",
    path: feedWiPLbu6A9uMeta?.path ?? "feed",
    meta: feedWiPLbu6A9uMeta || {},
    alias: feedWiPLbu6A9uMeta?.alias || [],
    redirect: feedWiPLbu6A9uMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersbiEEaZn0gDMeta?.name ?? "community-groups-group_id-members",
    path: membersbiEEaZn0gDMeta?.path ?? "members",
    meta: membersbiEEaZn0gDMeta || {},
    alias: membersbiEEaZn0gDMeta?.alias || [],
    redirect: membersbiEEaZn0gDMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45editW3LZYgQk19Meta?.name ?? "community-profile-edit",
    path: community_45profile_45editW3LZYgQk19Meta?.path ?? "/community-profile-edit",
    meta: community_45profile_45editW3LZYgQk19Meta || {},
    alias: community_45profile_45editW3LZYgQk19Meta?.alias || [],
    redirect: community_45profile_45editW3LZYgQk19Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Mhvr0RkUzpMeta?.name ?? "community-profile-id",
    path: _91id_93Mhvr0RkUzpMeta?.path ?? "/community-profile/:id()",
    meta: _91id_93Mhvr0RkUzpMeta || {},
    alias: _91id_93Mhvr0RkUzpMeta?.alias || [],
    redirect: _91id_93Mhvr0RkUzpMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: communitybhaf4fpyrnMeta?.name ?? "community",
    path: communitybhaf4fpyrnMeta?.path ?? "/community",
    meta: communitybhaf4fpyrnMeta || {},
    alias: communitybhaf4fpyrnMeta?.alias || [],
    redirect: communitybhaf4fpyrnMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_938u0aLTGZT4Meta?.name ?? "complete-registration-uuid",
    path: _91uuid_938u0aLTGZT4Meta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_938u0aLTGZT4Meta || {},
    alias: _91uuid_938u0aLTGZT4Meta?.alias || [],
    redirect: _91uuid_938u0aLTGZT4Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93m6p5OodB1JMeta?.name ?? "contracts-unique_id",
    path: _91unique_id_93m6p5OodB1JMeta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93m6p5OodB1JMeta || {},
    alias: _91unique_id_93m6p5OodB1JMeta?.alias || [],
    redirect: _91unique_id_93m6p5OodB1JMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardwsp4STXZYpMeta?.name ?? "dashboard",
    path: dashboardwsp4STXZYpMeta?.path ?? "/dashboard",
    meta: dashboardwsp4STXZYpMeta || {},
    alias: dashboardwsp4STXZYpMeta?.alias || [],
    redirect: dashboardwsp4STXZYpMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: index7UwLGcM1lVMeta?.name ?? "event-details-event_id",
    path: index7UwLGcM1lVMeta?.path ?? "/event/details/:event_id()",
    meta: index7UwLGcM1lVMeta || {},
    alias: index7UwLGcM1lVMeta?.alias || [],
    redirect: index7UwLGcM1lVMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutvPgoMYTLDxMeta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutvPgoMYTLDxMeta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutvPgoMYTLDxMeta || {},
    alias: checkoutvPgoMYTLDxMeta?.alias || [],
    redirect: checkoutvPgoMYTLDxMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45productg4bzYTuLSGMeta?.name ?? "event-details-event_id-select-product",
    path: select_45productg4bzYTuLSGMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45productg4bzYTuLSGMeta || {},
    alias: select_45productg4bzYTuLSGMeta?.alias || [],
    redirect: select_45productg4bzYTuLSGMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventsKpUwHhBkxAMeta?.name ?? "events",
    path: eventsKpUwHhBkxAMeta?.path ?? "/events",
    meta: eventsKpUwHhBkxAMeta || {},
    alias: eventsKpUwHhBkxAMeta?.alias || [],
    redirect: eventsKpUwHhBkxAMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93B7ZqGkYaxwMeta?.name ?? "gifts-uuid",
    path: _91uuid_93B7ZqGkYaxwMeta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93B7ZqGkYaxwMeta || {},
    alias: _91uuid_93B7ZqGkYaxwMeta?.alias || [],
    redirect: _91uuid_93B7ZqGkYaxwMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexG05mlnrfZlMeta?.name ?? "gifts",
    path: indexG05mlnrfZlMeta?.path ?? "/gifts",
    meta: indexG05mlnrfZlMeta || {},
    alias: indexG05mlnrfZlMeta?.alias || [],
    redirect: indexG05mlnrfZlMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_93Pa3io898jAMeta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_93Pa3io898jAMeta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_93Pa3io898jAMeta || {},
    alias: _91invitation_id_93Pa3io898jAMeta?.alias || [],
    redirect: _91invitation_id_93Pa3io898jAMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93ZsY7ZC0WwBMeta?.name ?? "gifts-product-product_id",
    path: _91product_id_93ZsY7ZC0WwBMeta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_93ZsY7ZC0WwBMeta || {},
    alias: _91product_id_93ZsY7ZC0WwBMeta?.alias || [],
    redirect: _91product_id_93ZsY7ZC0WwBMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: index2Qy6Q6hO5MMeta?.name ?? "index",
    path: index2Qy6Q6hO5MMeta?.path ?? "/",
    meta: index2Qy6Q6hO5MMeta || {},
    alias: index2Qy6Q6hO5MMeta?.alias || [],
    redirect: index2Qy6Q6hO5MMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_932jHNyZsmkZMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_932jHNyZsmkZMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_932jHNyZsmkZMeta || {},
    alias: _91invitation_unique_id_932jHNyZsmkZMeta?.alias || [],
    redirect: _91invitation_unique_id_932jHNyZsmkZMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: join0LiZSC3lniMeta?.name ?? "join",
    path: join0LiZSC3lniMeta?.path ?? "/join",
    meta: join0LiZSC3lniMeta || {},
    alias: join0LiZSC3lniMeta?.alias || [],
    redirect: join0LiZSC3lniMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: loginkoSlsSoaucMeta?.name ?? "login",
    path: loginkoSlsSoaucMeta?.path ?? "/login",
    meta: loginkoSlsSoaucMeta || {},
    alias: loginkoSlsSoaucMeta?.alias || [],
    redirect: loginkoSlsSoaucMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logoutZIlabLKLU5Meta?.name ?? "logout",
    path: logoutZIlabLKLU5Meta?.path ?? "/logout",
    meta: logoutZIlabLKLU5Meta || {},
    alias: logoutZIlabLKLU5Meta?.alias || [],
    redirect: logoutZIlabLKLU5Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_93a8wk7Yp6oYMeta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_93a8wk7Yp6oYMeta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_93a8wk7Yp6oYMeta || {},
    alias: _91meeting_type_slug_93a8wk7Yp6oYMeta?.alias || [],
    redirect: _91meeting_type_slug_93a8wk7Yp6oYMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93t4XMBU8KULMeta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_93t4XMBU8KULMeta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_93t4XMBU8KULMeta || {},
    alias: _91meeting_unique_id_93t4XMBU8KULMeta?.alias || [],
    redirect: _91meeting_unique_id_93t4XMBU8KULMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93qOJxHyypZDMeta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_93qOJxHyypZDMeta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_93qOJxHyypZDMeta || {},
    alias: _91meeting_unique_id_93qOJxHyypZDMeta?.alias || [],
    redirect: _91meeting_unique_id_93qOJxHyypZDMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesDmRajmQX8GMeta?.name ?? "my-favorites",
    path: my_45favoritesDmRajmQX8GMeta?.path ?? "/my-favorites",
    meta: my_45favoritesDmRajmQX8GMeta || {},
    alias: my_45favoritesDmRajmQX8GMeta?.alias || [],
    redirect: my_45favoritesDmRajmQX8GMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studioskebHFaCLpsMeta?.name ?? "my-studios",
    path: my_45studioskebHFaCLpsMeta?.path ?? "/my-studios",
    meta: my_45studioskebHFaCLpsMeta || {},
    alias: my_45studioskebHFaCLpsMeta?.alias || [],
    redirect: my_45studioskebHFaCLpsMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optinjtxXTLgvu1Meta?.name ?? "optin",
    path: optinjtxXTLgvu1Meta?.path ?? "/optin",
    meta: optinjtxXTLgvu1Meta || {},
    alias: optinjtxXTLgvu1Meta?.alias || [],
    redirect: optinjtxXTLgvu1Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_93tTe67zw6RLMeta?.name ?? "podcasts-podcast_id-episodes-episode_id",
    path: _91episode_id_93tTe67zw6RLMeta?.path ?? "/podcasts/:podcast_id()/episodes/:episode_id()",
    meta: _91episode_id_93tTe67zw6RLMeta || {},
    alias: _91episode_id_93tTe67zw6RLMeta?.alias || [],
    redirect: _91episode_id_93tTe67zw6RLMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: indexveSfqpxIBaMeta?.name ?? "podcasts-podcast_id",
    path: indexveSfqpxIBaMeta?.path ?? "/podcasts/:podcast_id()",
    meta: indexveSfqpxIBaMeta || {},
    alias: indexveSfqpxIBaMeta?.alias || [],
    redirect: indexveSfqpxIBaMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwe5Z5QtZNBMeta?.name ?? "podcasts",
    path: indexwe5Z5QtZNBMeta?.path ?? "/podcasts",
    meta: indexwe5Z5QtZNBMeta || {},
    alias: indexwe5Z5QtZNBMeta?.alias || [],
    redirect: indexwe5Z5QtZNBMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93p2ocXnnfK6Meta?.name ?? "product-product_id",
    path: _91product_id_93p2ocXnnfK6Meta?.path ?? "/product/:product_id()",
    meta: _91product_id_93p2ocXnnfK6Meta || {},
    alias: _91product_id_93p2ocXnnfK6Meta?.alias || [],
    redirect: _91product_id_93p2ocXnnfK6Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutGe4REoB4O3Meta?.name ?? "product-product_id-about",
    path: aboutGe4REoB4O3Meta?.path ?? "about",
    meta: aboutGe4REoB4O3Meta || {},
    alias: aboutGe4REoB4O3Meta?.alias || [],
    redirect: aboutGe4REoB4O3Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: digital_45download_45contentyMGu8jnyAAMeta?.name ?? "product-product_id-digital-download-content",
    path: digital_45download_45contentyMGu8jnyAAMeta?.path ?? "digital-download-content",
    meta: digital_45download_45contentyMGu8jnyAAMeta || {},
    alias: digital_45download_45contentyMGu8jnyAAMeta?.alias || [],
    redirect: digital_45download_45contentyMGu8jnyAAMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/digital-download-content.vue").then(m => m.default || m)
  },
  {
    name: eventsvWuMw98CGCMeta?.name ?? "product-product_id-events",
    path: eventsvWuMw98CGCMeta?.path ?? "events",
    meta: eventsvWuMw98CGCMeta || {},
    alias: eventsvWuMw98CGCMeta?.alias || [],
    redirect: eventsvWuMw98CGCMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93l19xXdIjnvMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_93l19xXdIjnvMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93l19xXdIjnvMeta || {},
    alias: _91media_id_93l19xXdIjnvMeta?.alias || [],
    redirect: _91media_id_93l19xXdIjnvMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleqNskbRpVCvMeta?.name ?? "product-product_id-free-sample",
    path: free_45sampleqNskbRpVCvMeta?.path ?? "free-sample",
    meta: free_45sampleqNskbRpVCvMeta || {},
    alias: free_45sampleqNskbRpVCvMeta?.alias || [],
    redirect: free_45sampleqNskbRpVCvMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsRkVUyfrljnMeta?.name ?? "product-product_id-included-products",
    path: included_45productsRkVUyfrljnMeta?.path ?? "included-products",
    meta: included_45productsRkVUyfrljnMeta || {},
    alias: included_45productsRkVUyfrljnMeta?.alias || [],
    redirect: included_45productsRkVUyfrljnMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93vbItQYOd7vMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93vbItQYOd7vMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93vbItQYOd7vMeta || {},
    alias: _91media_id_93vbItQYOd7vMeta?.alias || [],
    redirect: _91media_id_93vbItQYOd7vMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewDz3cymlnlXMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewDz3cymlnlXMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewDz3cymlnlXMeta || {},
    alias: viewDz3cymlnlXMeta?.alias || [],
    redirect: viewDz3cymlnlXMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexYIIRke4oA3Meta?.name ?? "product-product_id-lessons",
    path: indexYIIRke4oA3Meta?.path ?? "lessons",
    meta: indexYIIRke4oA3Meta || {},
    alias: indexYIIRke4oA3Meta?.alias || [],
    redirect: indexYIIRke4oA3Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93kznGRJLXqQMeta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_93kznGRJLXqQMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93kznGRJLXqQMeta || {},
    alias: _91media_id_93kznGRJLXqQMeta?.alias || [],
    redirect: _91media_id_93kznGRJLXqQMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexfRiCPTdPvNMeta?.name ?? "product-product_id-playlists-playlist_id",
    path: indexfRiCPTdPvNMeta?.path ?? "playlists/:playlist_id()",
    meta: indexfRiCPTdPvNMeta || {},
    alias: indexfRiCPTdPvNMeta?.alias || [],
    redirect: indexfRiCPTdPvNMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93GbmSi7BgPJMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93GbmSi7BgPJMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93GbmSi7BgPJMeta || {},
    alias: _91media_id_93GbmSi7BgPJMeta?.alias || [],
    redirect: _91media_id_93GbmSi7BgPJMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indextcfNmQgvskMeta?.name ?? "product-product_id-playlists",
    path: indextcfNmQgvskMeta?.path ?? "playlists",
    meta: indextcfNmQgvskMeta || {},
    alias: indextcfNmQgvskMeta?.alias || [],
    redirect: indextcfNmQgvskMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsGrAPTQV2zIMeta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionsGrAPTQV2zIMeta?.path ?? "private-sessions",
    meta: private_45sessionsGrAPTQV2zIMeta || {},
    alias: private_45sessionsGrAPTQV2zIMeta?.alias || [],
    redirect: private_45sessionsGrAPTQV2zIMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordings2s9giX44q9Meta?.name ?? "product-product_id-recordings",
    path: recordings2s9giX44q9Meta?.path ?? "recordings",
    meta: recordings2s9giX44q9Meta || {},
    alias: recordings2s9giX44q9Meta?.alias || [],
    redirect: recordings2s9giX44q9Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsiQPxfOez8pMeta?.name ?? "product-product_id-reviews",
    path: reviewsiQPxfOez8pMeta?.path ?? "reviews",
    meta: reviewsiQPxfOez8pMeta || {},
    alias: reviewsiQPxfOez8pMeta?.alias || [],
    redirect: reviewsiQPxfOez8pMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexAuTjsnwtTXMeta?.name ?? "product",
    path: indexAuTjsnwtTXMeta?.path ?? "/product",
    meta: indexAuTjsnwtTXMeta || {},
    alias: indexAuTjsnwtTXMeta?.alias || [],
    redirect: indexAuTjsnwtTXMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexdtv2ZTXx6fMeta?.name ?? "profile",
    path: indexdtv2ZTXx6fMeta?.path ?? "/profile",
    meta: indexdtv2ZTXx6fMeta || {},
    alias: indexdtv2ZTXx6fMeta?.alias || [],
    redirect: indexdtv2ZTXx6fMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesDLhLeIuHUZMeta?.name ?? "profile-purchases",
    path: purchasesDLhLeIuHUZMeta?.path ?? "/profile/purchases",
    meta: purchasesDLhLeIuHUZMeta || {},
    alias: purchasesDLhLeIuHUZMeta?.alias || [],
    redirect: purchasesDLhLeIuHUZMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptions5oKCLNm1A0Meta?.name ?? "profile-redemptions",
    path: redemptions5oKCLNm1A0Meta?.path ?? "/profile/redemptions",
    meta: redemptions5oKCLNm1A0Meta || {},
    alias: redemptions5oKCLNm1A0Meta?.alias || [],
    redirect: redemptions5oKCLNm1A0Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93hSglc3doxaMeta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93hSglc3doxaMeta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93hSglc3doxaMeta || {},
    alias: _91quiz_id_93hSglc3doxaMeta?.alias || [],
    redirect: _91quiz_id_93hSglc3doxaMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93mjkjM3Cd71Meta?.name ?? "reset-password-uid-token",
    path: _91token_93mjkjM3Cd71Meta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_93mjkjM3Cd71Meta || {},
    alias: _91token_93mjkjM3Cd71Meta?.alias || [],
    redirect: _91token_93mjkjM3Cd71Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: resetl1zD3qDjbTMeta?.name ?? "reset",
    path: resetl1zD3qDjbTMeta?.path ?? "/reset",
    meta: resetl1zD3qDjbTMeta || {},
    alias: resetl1zD3qDjbTMeta?.alias || [],
    redirect: resetl1zD3qDjbTMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shop88DDXHgXcTMeta?.name ?? "shop",
    path: shop88DDXHgXcTMeta?.path ?? "/shop",
    meta: shop88DDXHgXcTMeta || {},
    alias: shop88DDXHgXcTMeta?.alias || [],
    redirect: shop88DDXHgXcTMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signupu8PTvmCgbqMeta?.name ?? "signup",
    path: signupu8PTvmCgbqMeta?.path ?? "/signup",
    meta: signupu8PTvmCgbqMeta || {},
    alias: signupu8PTvmCgbqMeta?.alias || [],
    redirect: signupu8PTvmCgbqMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: liveO95mSX99HiMeta?.name ?? "streaming-event_id-live",
    path: liveO95mSX99HiMeta?.path ?? "/streaming/:event_id()/live",
    meta: liveO95mSX99HiMeta || {},
    alias: liveO95mSX99HiMeta?.alias || [],
    redirect: liveO95mSX99HiMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tips14zY4LNUb0Meta?.name ?? "tips",
    path: tips14zY4LNUb0Meta?.path ?? "/tips",
    meta: tips14zY4LNUb0Meta || {},
    alias: tips14zY4LNUb0Meta?.alias || [],
    redirect: tips14zY4LNUb0Meta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93EF37R6m2DGMeta?.name ?? "waiver-uuid",
    path: _91uuid_93EF37R6m2DGMeta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_93EF37R6m2DGMeta || {},
    alias: _91uuid_93EF37R6m2DGMeta?.alias || [],
    redirect: _91uuid_93EF37R6m2DGMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compactiIFjEBZGOAMeta?.name ?? "widgets-calendars-compact",
    path: compactiIFjEBZGOAMeta?.path ?? "/widgets/calendars/compact",
    meta: compactiIFjEBZGOAMeta || {},
    alias: compactiIFjEBZGOAMeta?.alias || [],
    redirect: compactiIFjEBZGOAMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: full1EMZkSeOYFMeta?.name ?? "widgets-calendars-full",
    path: full1EMZkSeOYFMeta?.path ?? "/widgets/calendars/full",
    meta: full1EMZkSeOYFMeta || {},
    alias: full1EMZkSeOYFMeta?.alias || [],
    redirect: full1EMZkSeOYFMeta?.redirect,
    component: () => import("/tmp/build_7e8da4c2/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]