import revive_payload_client_4sVQNw7RlN from "/tmp/build_7e8da4c2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/tmp/build_7e8da4c2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/tmp/build_7e8da4c2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/tmp/build_7e8da4c2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/build_7e8da4c2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/tmp/build_7e8da4c2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/tmp/build_7e8da4c2/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/tmp/build_7e8da4c2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import useDirectives_W0tXrzvhuP from "/tmp/build_7e8da4c2/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.mjs";
import createBootstrap_ywvW1KIeJ9 from "/tmp/build_7e8da4c2/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.mjs";
import plugin_ZGw8LlWmeq from "/tmp/build_7e8da4c2/node_modules/nuxt3-notifications/dist/runtime/plugin.mjs";
import floating_vue_EIcJ7xiw0h from "/tmp/build_7e8da4c2/.nuxt/floating-vue.mjs";
import i18n_yfWm7jX06p from "/tmp/build_7e8da4c2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_j7wLaRg9eH from "/tmp/build_7e8da4c2/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/tmp/build_7e8da4c2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/tmp/build_7e8da4c2/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import analytics_client_mtqVw3YRXk from "/tmp/build_7e8da4c2/plugins/analytics.client.ts";
import api_QQzOodE9HA from "/tmp/build_7e8da4c2/plugins/api.js";
import filterPhone_Wo9KAlPFDg from "/tmp/build_7e8da4c2/plugins/filterPhone.ts";
import firebase_client_zXNDa0wxFH from "/tmp/build_7e8da4c2/plugins/firebase.client.ts";
import form_x1SIvg4SAv from "/tmp/build_7e8da4c2/plugins/form.ts";
import format_wexqKEqeoL from "/tmp/build_7e8da4c2/plugins/format.ts";
import infinite_scroll_client_KPYf9EZyPg from "/tmp/build_7e8da4c2/plugins/infinite-scroll.client.ts";
import modal_UEFnUXzgTX from "/tmp/build_7e8da4c2/plugins/modal.ts";
import ssr_reset_TY53B5ymxM from "/tmp/build_7e8da4c2/plugins/ssr-reset.js";
import studioURL_POw8ww8dSP from "/tmp/build_7e8da4c2/plugins/studioURL.ts";
import swiper_xwaaUO4rVf from "/tmp/build_7e8da4c2/plugins/swiper.js";
import toast_ysMjUcU7eJ from "/tmp/build_7e8da4c2/plugins/toast.ts";
import v_confirm_directive_7kh9s2fuQE from "/tmp/build_7e8da4c2/plugins/v-confirm-directive.ts";
import v_select_pBubpC5a9f from "/tmp/build_7e8da4c2/plugins/v-select.ts";
import vue_final_modal_pML93k5qcp from "/tmp/build_7e8da4c2/plugins/vue-final-modal.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  useDirectives_W0tXrzvhuP,
  createBootstrap_ywvW1KIeJ9,
  plugin_ZGw8LlWmeq,
  floating_vue_EIcJ7xiw0h,
  i18n_yfWm7jX06p,
  plugin_client_j7wLaRg9eH,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  analytics_client_mtqVw3YRXk,
  api_QQzOodE9HA,
  filterPhone_Wo9KAlPFDg,
  firebase_client_zXNDa0wxFH,
  form_x1SIvg4SAv,
  format_wexqKEqeoL,
  infinite_scroll_client_KPYf9EZyPg,
  modal_UEFnUXzgTX,
  ssr_reset_TY53B5ymxM,
  studioURL_POw8ww8dSP,
  swiper_xwaaUO4rVf,
  toast_ysMjUcU7eJ,
  v_confirm_directive_7kh9s2fuQE,
  v_select_pBubpC5a9f,
  vue_final_modal_pML93k5qcp
]